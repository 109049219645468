* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  //font-size: 62.5%;
  //background: radial-gradient(ellipse at center, #000 0, #bc1339 80%, #e52325 100%);
  //background-color: #CCC;
}
body {
  // font: 300 13px/1.6 'Helvetica Neue', Helvetica, Arial;
  // color: #444;
  transition: all .4s .3s ease-in;
}
