$serif: "adobe-caslon-pro", courier, 'Cardo', serif;
$sans: "ff-din-web", courier, sans-serif;

$main: rgba(178,153,73,1);

//1366 x 1024

* {
	font-family: $sans;
}

body {
	background-color: rgba(200,200,200,0.5);

}
// ::-webkit-scrollbar {
//     width: 0px;  /* remove scrollbar space */
//     background: transparent;  /* optional: just make scrollbar invisible */
// }
// /* optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
//     background: #FF0000;
// }

a {
	text-decoration: none
}

.main_logo {
	width: 300px;
	margin: 30px auto 0;
	display: block;

}

.instructions {
	
	width: 200px;
	height: 200px;
	position: absolute;
	left: 1050px;
	top: -60px;

	.hand {
		position: absolute;	
		width: 90px;
		right: 0;
	}
	.scroll {
		position: absolute;	
		left: 20px;
		bottom: 20px;
		width: 100px;
	}
}

main {
	height: 800px;
	left: 20px;
	position: absolute;
	top: 150px;
	width: 1326px;
}

.column {
	float: left;
	height: 100%;
	margin-right: 10px;
	width: 324px;


	&:last-child {
		margin-right: 0;
	}

	h2.column_title {
		background-color: $main;
		border-radius: 5px 5px 0 0;
		color: white;
		display: block;
		margin: 0 0 0 0;
		padding: 20px 0;
		text-align: center;
		text-transform: uppercase;
		font-size: 23px;
		letter-spacing: -1px;
	}

	.column_slider {
		overflow: scroll;
		-webkit-overflow-scrolling: touch;	
		height: 100%;
		background-color: white;
		//border: 1px solid rgba(200,200,200,0.9);
		border-top: none;
		padding-bottom: 60px;
	
	}
}

.story {
	margin-bottom: 10px;
	border-bottom: 10px solid rgba(200,200,200,0.5);
	
	img {
		width: 100%;
		margin-top: -10px;
	}

	h3 {
		
		color: #333;
		
		font-family: $sans;
		font-size:23px;
		line-height: 120%;
		margin: 0 20px;
		padding: 10px 0;
		text-align: center;
		letter-spacing: -1px;
		height: 75px;
	}
}

/** Popup modals
 ---------------------------------------------------------*/

.remodal {

	i.down {
		position: absolute;
		bottom: 10px;
		left: 618px;
		color: $main;
		
	}

	.copy {
		//border: 1px solid red;
		width: 930px;
		height: 824px;
		overflow: scroll;
		margin: 0 auto;
	}

	h1 {
		text-align: left;
		font-size: 40px;
		margin-bottom: 0;
	}

	h2 {
		text-align: left;
		font-size: 30px;
		margin-bottom: 0;
		color: $main;
		margin-top: 60px;
		padding: 0;
	}

	h3 {
		text-align: left;
		font-size: 24px;
		margin-bottom: 0;
		margin-top: 30px;
		padding: 0;
	}

	div.standfirst p {
		font-size: 25px;
		margin-top: 10px;
		
		
	}

	p {
		font-size: 22px;
		text-align: left;
		line-height: 135%;
	}

	ul {
		li {
			font-size: 22px;
			text-align: left;
			margin-bottom: 10px;
			line-height: 135%;
		}
	}

	img {
		width: 100%;
	}

	blockquote {
		color: $main;
		font-size: 30px;
		font-family: $serif;
		width: 75%;
		text-align: center;
		margin: 0 auto;
		line-height: 40px;
	}

	.related {

		margin-top: 60px;

		h2 {
			color: $main;
			text-transform: uppercase;
			font-size: 30px;
			text-align: center;
		}

		.related_story {

		}
	}





}


.menubar {
	background-color: $main;
	width: 1366px;
	height: 75px;
	position: absolute;
	bottom: 0;
	left: 0;

	.social {
		width: 800px;
		margin: 0 auto;
		display: block;
		margin-top: 25px;
	}

	// .social {

	// 	list-style-type: none;
	// 	padding: 0;
		
	// 	overflow: hidden;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 50px;

	// 	li {
	// 		float: right;
			
	// 		margin-left: 30px;
	// 		line-height: 30px;
	// 		font-size: 20px;
	// 		color: white
	// 	}

	// 	span {
	// 		margin-top: -20px;
	// 	}
	// }

	i {
		padding: 0;
		margin: 0;
		color: white;
		font-size: 30px;
		font-weight: 100;
		margin-right: 5px;
		font-style: normal;
	}
}

	

a.home {

}


/** Splash screen
 ---------------------------------------------------------*/
body.splash {
	background-color: white;

	.hand {
		position: absolute;
		top: -250px;
		left: 530px;
		width: 220px;
	}
}

a.splash {

 	display: block;
 	margin: 250px auto;
 	width: 800px;
 	color: $main;

 	img {
 		width: 100%;
 	}

 	p {
 		text-align: center;
 		font-size: 50px;
 		font-weight: 700;
 		text-transform: uppercase;
 	}
}

.pulsate {
	animation: flickerAnimation 1.5s infinite;
}

@keyframes flickerAnimation { /* flame pulses */
  0%   { opacity:0.5; }
  50%  { opacity:1; }
  100% { opacity:0.5; }
}

.splash_social {
	width: 796px;
	margin: 0 auto;
	position: absolute;
	left:265px;
	bottom: 5px;
}










